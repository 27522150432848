<template>
  <div>
    <section class="mx-4 lg:mr-0 lg:ml-12 my-10" v-if="featured.length > 0">
      <ClientOnly>
        <FeaturedEventsSlider :events="featured" />
      </ClientOnly>
    </section>

    <ClientOnly>
      <PortalFilterHeader
        class="mb-14"
        :categories="categories"
        :showDatePicker="false"
        :selectedCategoriesIds="[]"
        @searchEvents="searchEvents"
        @toggleCategory="toggleCategory"
      />
    </ClientOnly>

    <section class="mx-4 lg:mr-0 lg:ml-12 pt-1" v-if="soon.length > 0">
      <ClientOnly>
        <EventsSlider :events="soon">
          <template #title>
            <span class="inline-flex gap-2 items-center">
              <WellIcon icon="hourglass-01" />
              <span>HAPPENING SOON</span>
            </span>
          </template>
        </EventsSlider>
      </ClientOnly>
    </section>

    <hr class="my-12 border-dark" v-if="newlyAdded.length > 0"/>
    <section class="mx-4 lg:mr-0 lg:ml-12" v-if="newlyAdded.length > 0">
      <ClientOnly>
        <EventsSlider :events="newlyAdded">
          <template #title>
            <span class="inline-flex gap-2 items-center">
              <WellIcon icon="announcement-02" />
              <span>RECENTLY ADDED</span>
            </span>
          </template>
        </EventsSlider>
      </ClientOnly>
    </section>

    <hr class="my-12 border-dark" v-if="trending.length > 0"/>   
    <section class="mx-4 lg:mr-0 lg:ml-12" v-if="trending.length > 0">
      <ClientOnly>
        <EventsSlider :events="trending">
          <template #title>
            <span class="inline-flex gap-2 items-center">
              <WellIcon icon="line-chart-up-03" />
              <span>TRENDING</span>
            </span>
          </template>
        </EventsSlider>
      </ClientOnly>
    </section>

    <hr class="my-12 border-dark" v-if="past.length > 0"/>   
    <section class="mx-4 lg:mr-0 lg:ml-12" v-if="past.length > 0">
      <ClientOnly>
        <EventsSlider :events="past">
          <template #title>
            <span class="inline-flex gap-2 items-center">
              <WellIcon icon="diamond-01" />
              <span>PAST GEMS</span>
            </span>
          </template>
        </EventsSlider>
      </ClientOnly>
    </section>

    <hr class="mt-12 border-dark" />
    <Banner1 />
    <Banner2 />
    <OptInBanner />
  </div>
</template>

<script setup lang="ts">
import EventsSlider from '@/components/events/EventsSlider.vue'
import FeaturedEventsSlider from '@/components/events/FeaturedEventsSlider.vue'
import Banner1 from '@/components/banners/Banner1.vue'
import Banner2 from '@/components/banners/Banner2.vue'
import OptInBanner from '@/components/banners/OptInBanner.vue'
import PortalFilterHeader from '@/components/layout/PortalFilterHeader.vue'
import { useDialog } from 'primevue/usedialog';
import EventShareModal from '@/components/events/EventShareModal.vue';
import { useApi } from '@/composables/useApi'
import WellIcon from '~/components/common/WellIcon.vue'

const dialog = useDialog();
const shareEvent = () => {
  dialog.open(EventShareModal, {
    props: {
      draggable: false,
      dismissableMask: true,
      style: {
        width: '500px',
      },
      breakpoints:{
        '500px': '100vw'
      },
      modal: true
    }
  });
}

const { fetchDashboard } = useApi()

// Fetch data server-side
const { data: dashboard, error } = await fetchDashboard()

if (error?.value) {
  console.error('Error fetching dashboard data:', error.value)
}

const categories = computed(() => dashboard.value?.categories ?? [])
const featured = computed(() => dashboard.value?.featured ?? [])
const soon = computed(() => dashboard.value?.soon ?? [])
const newlyAdded = computed(() => dashboard.value?.newly_added ?? [])
const trending = computed(() => dashboard.value?.trending ?? [])
const past = computed(() => dashboard.value?.past ?? [])

const router = useRouter()

const searchEvents = (search: string) => {
  router.push(`/experiences?q=${search}`)
}

const toggleCategory = (category: CategoryResponse) => {
  router.push(`/experiences?categories=${category.id}`)
}
</script>

<style scoped>
hr {
  border-color: dark;
}
</style>
