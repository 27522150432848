<template>
  <div class="max-w-full flex flex-col md:flex-row">
    <header class="relative p-3 w-full aspect-square md:w-1/2 md:max-w-[408px]">
      <NuxtImg
        class="object-cover absolute inset-0 size-full"
        loading="lazy"
        :src="eventDetails.photo1"
      />
      <EventTypeTag
        class="relative"
        :event="event"
      />
    </header>
    <div class="event-details md:w-1/2 flex-grow md:ml-10">
      <div class="flex justify-between mt-8 md:mt-0">
        <span>FEATURED EVENTS</span>
        <div class="flex gap-8">
          <button
            v-if="index !== 0"
            class="flex items-center gap-2 custom-swiper-nav cursor-pointer"
            @click.prevent="$emit('slide', 'left')"
          >
            <WellIcon icon="arrow-left" :size="24" />
            <span>
              PREVIOUS
            </span>
          </button>
          <button
            class="flex items-center gap-2 custom-swiper-nav cursor-pointer"
            @click.prevent="$emit('slide', 'right')"
          >
            <span>
              NEXT
            </span>
            <WellIcon icon="arrow-right" :size="24" />
          </button>
        </div>
      </div>
      <h1 class="text-4xl mt-6 line-clamp-2">
        {{ event.event_name }}
      </h1>
      <div class="flex flex-row mt-4 md:mt-6 items-center flex-wrap">
        <EventDate :date="eventDetails.dateShort" />
        <span class="mx-3">•</span>
        <EventTime :time="eventDetails.time" />
      </div>
      <div class="flex flex-row mt-3 items-center flex-wrap mt-2">
        <EventLocation :event="eventDetails"/>
        <span class="mx-3">•</span>
        <EventPrice :price="eventDetails.www_price" />        
      </div>
      <div
          v-if="eventDetails.instructors.length > 0"
          class="flex items-center mt-4"
        >
          <InstructorsStack 
            :instructors="eventDetails.instructors" size="24px" 
        />
      </div>
      <div class="event-description-wrapper">
        <div
          class="flex mt-4 event-description"
          data-nosnippet
          v-html="event.event_description_new"
        />
      </div>
      <div class="flex mt-3">
        <NuxtLink :to="`/events/${eventDetails.id}`" class="text-primary underline">Register Now</NuxtLink>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps } from 'vue'
import { getEventDetails } from '~/utils/eventDetails';
import WellIcon from '@/components/common/WellIcon.vue';
import EventDate from '@/components/events/EventDate.vue';
import EventTime from '@/components/events/EventTime.vue';
import EventPrice from '@/components/events/EventPrice.vue';
import EventLocation from '@/components/events/EventLocation.vue';
import InstructorsStack from '@/components/events/InstructorsStack.vue';
import EventTypeTag from '@/components/events/EventTypeTag.vue';

const props = defineProps<{ event: EventResponse, index: number }>();

const eventDetails = getEventDetails(props.event);
</script>

<style lang="scss" scoped>
.event-description-wrapper {
  max-height: 15em;
  overflow: hidden;
}

.event-description {
  display: -webkit-box;
  line-clamp: 6;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;

  :deep(h3) {
    font-size: 1.25rem !important;
    margin-bottom: 0.5rem;
  }
  :deep(p) {
    margin-bottom: 1rem;
  }
  :deep(ul) {
    list-style-type: disc;
    margin-bottom: 1rem;
    margin-left: 1.25rem;
  }
  :deep(ul li) {
    padding-left: 0.2rem;
  }
  :deep(ol) {
    list-style-type: decimal;
    margin-left: 1.25rem;
    margin-bottom: 1rem;
  }
  :deep(ol li) {
    padding-left: 0.2rem;
  }
}

@media (min-width: 768px) {
  .event-description {
    max-width: 80%;
  }
}

</style>