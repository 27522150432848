<template>
  <div class="bg-dark text-white max-w-auto">
    <div class="flex flex-col items-center justify-center py-24 text-center max-w-[882px]  mx-auto px-4">
      <h2 class="pb-8 text-4.5xl">
        Host Your Own Experience On Well Well Well
      </h2>
      <p>
        Host a gathering. Host a workshop. Host a class. Host a coven. Host a conference. Host a reading. Host an unforgettable experience.
      </p>
      <a
        href="https://heymarvelous.com/well-well-well"
        class="underline mt-5 flex flex-row items-start"
      >
        <span class="mr-3">Join Well Well Well Now</span>
        <img src="/images/marvelous.png" alt="Marvelous" />
      </a>
    </div>
  </div>
</template>